define("flytrendy/controllers/view-campaign", ["exports", "flytrendy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service("session"),
    applicationController: Ember.inject.controller("application"),
    brand: null,
    queryParams: ["campaign"],
    observationDialog: null,
    compressor: null,
    data: {},
    loading_campaign: true,
    moode_1: "",
    moode_2: "",
    moode_3: "",
    moode_4: "",
    moode_5: "",
    moode_6: "",
    moode_1_w: 0,
    moode_2_w: 0,
    moode_3_w: 0,
    moode_4_w: 0,
    moode_5_w: 0,
    moode_6_w: 0,
    moode_1_h: 0,
    moode_2_h: 0,
    moode_3_h: 0,
    moode_4_h: 0,
    moode_5_h: 0,
    moode_6_h: 0,
    gender: null,
    init: function init() {},
    actions: {
      onLoading: function onLoading() {
        this.compressor = new ImageCompressor();
        var that = this;
        var logo = document.getElementById("campaign_logo");
        logo.addEventListener("change", function (evt) {
          var file = evt.target.files[0];

          if (!file) {
            return;
          }

          that.send("showImageDialog", file, 16 / 9, "campaign_logo");
        });
        var hero = document.getElementById("campaign_hero");
        hero.addEventListener("change", function (evt) {
          var file = evt.target.files[0];

          if (!file) {
            return;
          }

          that.send("showImageDialog", file, 1, "campaign_hero");
        });
      },
      loadData: function loadData() {
        var that = this;
        var slider = document.getElementById("age-slider");
        noUiSlider.create(slider, {
          start: [this.get("data.ageMin"), this.get("data.ageMax")],
          step: 1,
          behaviour: "drag",
          connect: true,
          tooltips: true,
          range: {
            min: 0,
            max: 100
          },
          format: {
            to: function to(value) {
              return value;
            },
            from: function from(value) {
              return value;
            }
          }
        });
        slider.noUiSlider.on("change", function (val) {
          that.set("data.ageMin", val[0]);
          that.set("data.ageMax", val[1]);
        });
        $("#publishdate").datetimepicker({
          format: "d/m/y",
          timepicker: false,
          scrollInput: false,
          scrollMonth: false,
          minDate: "0"
        });
        $("#publishdate").change(function () {
          var min_d = $("#publishdate").val();
          min_d = moment(min_d, "DD/MM/YY").format("YYYY/MM/DD");
          $("#publishuntil").datetimepicker({
            format: "d/m/y",
            timepicker: false,
            scrollInput: false,
            scrollMonth: false,
            minDate: min_d
          });
        });
        $("#publishuntil").datetimepicker({
          format: "d/m/y",
          timepicker: false,
          scrollInput: false,
          scrollMonth: false,
          minDate: "0"
        });

        if (that.get("data.gender") == "female") {
          that.send("onGenderChange", 2);
        } else if (that.get("data.gender") == "male") {
          that.send("onGenderChange", 1);
        } else if (that.get("data.gender") == "all") {
          that.send("onGenderChange", 3);
        } else {
          that.send("onGenderChange", 4);
        }

        $(".mood-board .mood-processing").hide();
        var mood1 = document.getElementById("file_input_1");
        mood1.addEventListener("change", function (evt) {
          var file = evt.target.files[0];
          if (!file) return;
          that.send("processMoodImage", file, function (base64DataURL, w, h) {
            that.set("moode_1", base64DataURL);
            that.set("moode_1_w", w);
            that.set("moode_1_h", h);
          });
        }, false);
        var mood2 = document.getElementById("file_input_2");
        mood2.addEventListener("change", function (evt) {
          var file = evt.target.files[0];
          if (!file) return;
          that.send("processMoodImage", file, function (base64DataURL, w, h) {
            that.set("moode_2", base64DataURL);
            that.set("moode_2_w", w);
            that.set("moode_2_h", h);
          });
        }, false);
        var mood3 = document.getElementById("file_input_3");
        mood3.addEventListener("change", function (evt) {
          var file = evt.target.files[0];
          if (!file) return;
          that.send("processMoodImage", file, function (base64DataURL, w, h) {
            that.set("moode_3", base64DataURL);
            that.set("moode_3_w", w);
            that.set("moode_3_h", h);
          });
        }, false);
        var mood4 = document.getElementById("file_input_4");
        mood4.addEventListener("change", function (evt) {
          var file = evt.target.files[0];
          if (!file) return;
          that.send("processMoodImage", file, function (base64DataURL, w, h) {
            that.set("moode_4", base64DataURL);
            that.set("moode_4_w", w);
            that.set("moode_4_h", h);
          });
        }, false);
        var mood5 = document.getElementById("file_input_5");
        mood5.addEventListener("change", function (evt) {
          var file = evt.target.files[0];
          if (!file) return;
          that.send("processMoodImage", file, function (base64DataURL, w, h) {
            that.set("moode_5", base64DataURL);
            that.set("moode_5_w", w);
            that.set("moode_5_h", h);
          });
        }, false);
        var mood6 = document.getElementById("file_input_6");
        mood6.addEventListener("change", function (evt) {
          var file = evt.target.files[0];
          if (!file) return;
          that.send("processMoodImage", file, function (base64DataURL, w, h) {
            that.set("moode_6", base64DataURL);
            that.set("moode_6_w", w);
            that.set("moode_6_h", h);
          });
        }, false);
      },
      setupMoodBoard: function setupMoodBoard(moods) {
        var mood_counter = "";
        this.set("moode_1", "");
        this.set("moode_2", "");
        this.set("moode_3", "");
        this.set("moode_4", "");
        this.set("moode_5", "");
        this.set("moode_6", "");
        this.set("moode_1_w", 0);
        this.set("moode_2_w", 0);
        this.set("moode_3_w", 0);
        this.set("moode_4_w", 0);
        this.set("moode_5_w", 0);
        this.set("moode_6_w", 0);
        this.set("moode_1_h", 0);
        this.set("moode_2_h", 0);
        this.set("moode_3_h", 0);
        this.set("moode_4_h", 0);
        this.set("moode_5_h", 0);
        this.set("moode_6_h", 0);

        for (var i = 0; i < moods.length; i++) {
          mood_counter = "moode_" + (i + 1);
          this.set(mood_counter, moods[i]);
        }
      },
      processMoodImage: function processMoodImage(file, cb) {
        $(".mood-board .mood-processing").show();
        this.compressor.compress(file, {
          quality: 0.6,
          maxWidth: 450
        }).then(function (results) {
          var reader = new FileReader();
          var base64DataURL;

          reader.onload = function (e) {
            base64DataURL = e.target.result;
            $(".mood-board .mood-processing").hide();
            var img = new Image();

            img.onload = function () {
              cb(base64DataURL, this.width, this.height);
            };

            img.src = base64DataURL;
          };

          reader.readAsDataURL(results);
        });
      },
      onGenderChange: function onGenderChange(gender) {
        if (gender == 1) {
          $(".campaign-card .gender-cont .male").toggleClass("selected");
        }

        if (gender == 2) {
          $(".campaign-card .gender-cont .female").toggleClass("selected");
        }

        if (gender == 3) {
          $(".campaign-card .gender-cont .female").toggleClass("selected");
          $(".campaign-card .gender-cont .male").toggleClass("selected");
        }

        var m = $(".campaign-card .gender-cont .male").hasClass("selected");
        var f = $(".campaign-card .gender-cont .female").hasClass("selected");

        if (m && f) {
          this.set("gender", "all");
        } else if (m && !f) {
          this.set("gender", "male");
        } else if (!m && f) {
          this.set("gender", "female");
        } else {
          this.set("gender", "");
        }
      },
      removeUploadedMood: function removeUploadedMood(mood_no, e) {
        var key = "moode_" + mood_no;
        this.set(key, "");
        $("#file_input_" + mood_no).val("");
        e.preventDefault();
        e.stopPropagation();
      },
      onSave: function onSave() {
        var userauth = this.get("session.data.authenticated.token_type") + " " + this.get("session.data.authenticated.access_token");
        var campaign_id = this.get("campaign");

        if (!campaign_id) {
          return;
        }

        var data = this.get("data");
        var publishdate = $("#publishdate").val();
        var publishuntil = $("#publishuntil").val();
        var campaignLogo = this.get("data.campaign_logo_url");
        var campaignHero = this.get("data.hero_image_url");
        var campaignLogoBase64 = this.get("data.campaign_logo");
        var campaignHeroBase64 = this.get("data.hero_image");

        if (!data.get("brand_comp_name") || !data.get("pro_comp_name") || !data.get("pro_desc") || !data.get("content_love") || !data.get("call_action") || !data.get("campaign_link")) {
          this.get("applicationController").send("showToast", "All fields are required", "Missing Details", "warning");
          return;
        }

        if (!this.get("gender")) {
          this.get("applicationController").send("showToast", "Gender is requried", "Missing Details", "warning");
          return;
        }

        if (!publishdate) {
          this.get("applicationController").send("showToast", "Publish date is required", "Missing Details", "warning");
          return;
        }

        if (!publishuntil) {
          this.get("applicationController").send("showToast", "Open until date is required", "Missing Details", "warning");
          return;
        }

        if (data.get("ageMax") == 0) {
          this.get("applicationController").send("showToast", "Age is invalide", "Missing Details", "warning");
          return;
        }

        if (!campaignLogo && !campaignLogoBase64 || !campaignHero && !campaignHeroBase64) {
          this.get("applicationController").send("showToast", "Campaign logo and hero images are required", "Missing Details", "warning");
          return;
        }

        var mood_board = [];

        if (this.get("moode_1")) {
          mood_board.push(this.get("moode_1"));
        }

        if (this.get("moode_2")) {
          mood_board.push(this.get("moode_2"));
        }

        if (this.get("moode_3")) {
          mood_board.push(this.get("moode_3"));
        }

        if (this.get("moode_4")) {
          mood_board.push(this.get("moode_4"));
        }

        if (this.get("moode_5")) {
          mood_board.push(this.get("moode_5"));
        }

        if (this.get("moode_6")) {
          mood_board.push(this.get("moode_6"));
        }

        var endpoint = _environment.default.APP.API_HOST + "/staff/campaigns",
            that = this;
        var this_pre_tags = {
          tiktok: this.get("data.pre_tags.tiktok"),
          facebook: this.get("data.pre_tags.facebook"),
          instagram: this.get("data.pre_tags.instagram"),
          linkedin: this.get("data.pre_tags.linkedin")
        };
        var data = {
          campaign: {
            campaign_id: campaign_id,
            brand_name: this.get("data.brand_comp_name"),
            pro_comp_name: data.get("pro_comp_name"),
            pro_desc: data.get("pro_desc"),
            content_love: data.get("content_love"),
            call_action: data.get("call_action"),
            mood_board_urls: data.get("mood_board_urls"),
            where_purchase: data.get("where_purchase"),
            campaign_link: data.get("campaign_link"),
            gender: this.get("gender"),
            publish_brief_app: moment(publishdate, "DD/MM/YYYY").format("YYYY-MM-DDThh:mm:ss"),
            open_until_date: moment(publishuntil, "DD/MM/YYYY").format("YYYY-MM-DDThh:mm:ss"),
            ageMax: data.get("ageMax"),
            ageMin: data.get("ageMin"),
            mood_board: [],
            pre_tags: this_pre_tags
          }
        };

        if (campaignLogoBase64) {
          data.campaign["campaign_logo"] = campaignLogoBase64;
        }

        if (campaignHeroBase64) {
          data.campaign["hero_image"] = campaignHeroBase64;
        } //console.log(JSON.stringify(this_pre_tags));
        //console.log(JSON.stringify(data));


        that.get("applicationController").send("showLoader");
        Ember.$.ajax({
          type: "PATCH",
          url: endpoint,
          data: JSON.stringify(data),
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader("Authorization", userauth);
            xhr.setRequestHeader("Content-Type", "application/json");
          },
          success: function success(res) {
            that.get("applicationController").send("hideLoader");

            if (res && res.campaign && res.campaign.success) {
              that.get("applicationController").send("showToast", "Campaign saved", "Success", null);

              if (mood_board.length > 0 || !mood_board.length && data.campaign.mood_board_urls.length) {
                that.get("applicationController").send("showToast", "Saving images", "Mood Board", "warning");
                that.send("deleteMoodImages", campaign_id, userauth, function () {
                  that.send("pushMoodImages", campaign_id, mood_board, 0, userauth, function () {
                    that.get("applicationController").send("showToast", "Images saved", "Mood Board", null);
                  });
                });
              }
            } else {
              that.get("applicationController").send("showToast", "Something went wrong", "Oops", "error");
            }
          },
          error: function error(jqXHR, exception) {
            that.get("applicationController").send("hideLoader");
            that.get("applicationController").send("showToast", "Something went wrong", "Oops", "error");
          }
        });
      },
      pushMoodImages: function pushMoodImages(campaign_id, mood_board, index, cred, cb) {
        if (index < mood_board.length) {
          var data = {
            campaign_id: campaign_id,
            mood_image: mood_board[index]
          };
          var that = this;
          Ember.$.ajax({
            type: "POST",
            url: _environment.default.APP.API_HOST + "/addcampaignmoodimage",
            data: data,
            beforeSend: function beforeSend(xhr) {
              xhr.setRequestHeader("Authorization", cred);
              xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
            },
            success: function success(res) {
              that.send("pushMoodImages", campaign_id, mood_board, index + 1, cred, cb);
            },
            error: function error(e, exception) {
              that.send("pushMoodImages", campaign_id, mood_board, index + 1, cred, cb);
            }
          });
        } else {
          cb();
        }
      },
      deleteMoodImages: function deleteMoodImages(campaign_id, cred, cb) {
        var data = JSON.stringify({
          campaign_id: campaign_id
        });
        Ember.$.ajax({
          type: "DELETE",
          dataType: "json",
          url: _environment.default.APP.API_HOST + "/deletecampaignmoodimage",
          data: data,
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader("Authorization", cred);
            xhr.setRequestHeader("Content-Type", "application/json");
          },
          success: function success(res) {
            cb();
          },
          error: function error(e, exception) {
            that.get("applicationController").send("showToast", "Please, try again", "", null);
          }
        });
      },
      onPublish: function onPublish() {
        var userauth = this.get("session.data.authenticated.token_type") + " " + this.get("session.data.authenticated.access_token");
        var campaign_id = this.get("campaign");

        if (!campaign_id) {
          return;
        }

        this.get("applicationController").send("showLoader");
        var endpoint = _environment.default.APP.API_HOST + "/staff/campaigns",
            that = this;
        var data = {
          campaign: {
            campaign_id: campaign_id,
            reviewed: true
          }
        };
        Ember.$.ajax({
          type: "PATCH",
          url: endpoint,
          data: JSON.stringify(data),
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader("Authorization", userauth);
            xhr.setRequestHeader("Content-Type", "application/json");
          },
          success: function success(res) {
            that.get("applicationController").send("hideLoader");

            if (res && res.campaign && res.campaign.success) {
              that.set("data.reviewed", true);
              var campaignsCountStaff = that.get("session.data.campaigns_pending_count");
              that.set("session.data.campaigns_pending_count", campaignsCountStaff - 1);
              that.get("applicationController").send("showToast", "Campaign is live now", "Success", null);
            } else {
              that.get("applicationController").send("showToast", "Something went wrong", "Oops", "error");
            }
          },
          error: function error(jqXHR, exception) {
            that.get("applicationController").send("hideLoader");
            that.get("applicationController").send("showToast", "Something went wrong", "Oops", "error");
          }
        });
      },
      showImageDialog: function showImageDialog(file, aspectRatio, dataID) {
        var that = this;
        $("#crop_hero_image_subcont").text("");
        $("#campaign_image_loader").show();
        that.compressor.compress(file, {
          quality: 0.8
        }).then(function (results) {
          var reader = new FileReader();
          var base64DataURL;

          reader.onload = function (e) {
            base64DataURL = e.target.result;
            $("#crop_hero_image_subcont").text("");
            $("#crop_hero_image_subcont").append('<div><img data-id="' + dataID + '" id="campaign_image_cropper" style="max-width: 100%; max-height: 700px;" src="' + base64DataURL + '"></div>');
            $("#campaign_image_cropper").cropper({
              aspectRatio: aspectRatio,
              zoomable: false,
              crop: function crop(e) {}
            });
          };

          reader.readAsDataURL(results);
        });
      },
      onCancelCropping: function onCancelCropping() {
        $("#campaign_image_loader").hide();
      },
      onApplyCropping: function onApplyCropping() {
        var that = this;
        var cropped = $("#campaign_image_cropper").cropper("getCroppedCanvas");
        var dataID = $("#campaign_image_cropper").attr("data-id");
        setTimeout(function () {
          fetch(cropped.toDataURL("image/png")).then(function (res) {
            return res.blob();
          }).then(function (blob) {
            var file = new File([blob], "logo.png");
            that.compressor.compress(blob, {
              quality: 0.6,
              maxWidth: 450
            }).then(function (results) {
              var reader = new FileReader();
              var base64DataURL;

              reader.onload = function (e) {
                base64DataURL = e.target.result;

                if (dataID == "campaign_logo") {
                  that.set("data.campaign_logo", base64DataURL);
                } else if (dataID == "campaign_hero") {
                  that.set("data.hero_image", base64DataURL);
                }

                $("#campaign_image_loader").hide();
              };

              reader.readAsDataURL(results);
            });
          });
        }, 1000);
      }
    }
  });

  _exports.default = _default;
});