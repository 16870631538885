define("flytrendy/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "riq0TRZ0",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"login\"],[12,\"style\",[28,[\"background-image: url('\",[21,\"backImage\"],\"')\"]]],[9],[0,\"\\n\\t\"],[7,\"div\"],[11,\"class\",\"main-container\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\"],[11,\"class\",\"form-container\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"form\"],[11,\"class\",\"form-horizontal\"],[11,\"role\",\"form\"],[3,\"action\",[[22,0,[]],\"authenticate\"],[[\"on\"],[\"submit\"]]],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"p\"],[9],[0,\"Staff Login\"],[10],[0,\"\\n\\t\\t\\t  \\t\"],[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"label\"],[9],[10],[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[27,\"input\",null,[[\"id\",\"placeholder\",\"class\",\"type\",\"maxlength\",\"value\"],[\"identification\",\"Email\",\"form-control\",\"email\",\"100\",[23,[\"identification\"]]]]],false],[0,\"\\n\\t\\t\\t\\t\"],[10],[0,\"\\n\\t\\t\\t   \"],[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"label\"],[9],[10],[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[27,\"input\",null,[[\"id\",\"placeholder\",\"class\",\"type\",\"value\"],[\"password\",\"Password\",\"form-control\",\"password\",[23,[\"password\"]]]]],false],[0,\"\\n\\t\\t\\t\\t\"],[10],[0,\"\\n\\t\\t\\t  \\t\"],[7,\"div\"],[11,\"class\",\"form-group login-btn\"],[9],[0,\"\\n\\t\\t\\t  \\t\\t\\n\\t  \\t\\t\\t\\t\"],[7,\"input\"],[11,\"data-role\",\"button\"],[11,\"class\",\"btn-submit\"],[11,\"value\",\"Login\"],[11,\"type\",\"submit\"],[9],[10],[0,\"\\n\\t\\t\\t    \"],[10],[0,\"\\n\\t\\t\\t\"],[10],[0,\"\\n\\t\\t\"],[10],[0,\"\\n\\t\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "flytrendy/templates/login.hbs"
    }
  });

  _exports.default = _default;
});