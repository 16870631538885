define("flytrendy/controllers/application", ["exports", "flytrendy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service("session"),
    notifications: Ember.inject.service("toast"),
    platforms: _environment.default.APP.platforms[0],
    init: function init() {
      var that = this;
      setTimeout(function () {
        that.send("getPendingPostCount");
      }, 1000);
    },
    actions: {
      showLoader: function showLoader() {
        var h = $(window).height();
        h = h / 2;
        $(".loading-overlay .cont").css({
          marginTop: h
        });
        $(".loading-overlay").show();
      },
      showToast: function showToast(title, text, type) {
        var options = {
          closeButton: false,
          debug: false,
          newestOnTop: false,
          progressBar: false,
          positionClass: "toast-bottom-right",
          preventDuplicates: false,
          onclick: null,
          showDuration: "300",
          hideDuration: "1000",
          timeOut: "5000",
          extendedTimeOut: "1000",
          showEasing: "swing",
          hideEasing: "linear",
          showMethod: "fadeIn",
          hideMethod: "fadeOut"
        };

        if (type == "info") {
          this.get("notifications").info(title, text, options);
        } else if (type == "warning") {
          this.get("notifications").warning(title, text, options);
        } else if (type == "error") {
          this.get("notifications").error(title, text, options);
        } else {
          this.get("notifications").success(title, text, options);
        }
      },
      getPendingPostCount: function getPendingPostCount() {
        var token = this.get("session.data.authenticated.token_type");

        if (!token) {
          return;
        }

        var userauth = this.get("session.data.authenticated.token_type") + " " + this.get("session.data.authenticated.access_token");
        var endpoint = _environment.default.APP.API_HOST + "/staff/campaigncount?status=pending",
            that = this;
        Ember.$.ajax({
          type: "GET",
          url: endpoint,
          dataType: "json",
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader("Authorization", userauth);
            xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
          },
          success: function success(res) {
            that.set("session.data.campaigns_pending_count", res.getCampaignsCountStaff); // setTimeout(function() {
            // 	that.send("getPendingPostCount");
            // }, 60000);
          },
          error: function error(jqXHR, exception) {
            // setTimeout(function() {
            // 	that.send("getPendingPostCount");
            // }, 60000);
            that.set("session.data.campaigns_pending_count", 0);
          }
        });
        endpoint = _environment.default.APP.API_HOST + "/staff/postcount?status=pending";
        Ember.$.ajax({
          type: "GET",
          url: endpoint,
          dataType: "json",
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader("Authorization", userauth);
            xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
          },
          success: function success(res) {
            that.set("session.data.posts_pending_count", res.getPostsCountStaff); // setTimeout(function() {
            // 	that.send("getPendingPostCount");
            // }, 60000);
          },
          error: function error(jqXHR, exception) {
            // setTimeout(function() {
            // 	that.send("getPendingPostCount");
            // }, 60000);
            that.set("session.data.posts_pending_count", 0);
          }
        });
      },
      hideLoader: function hideLoader() {
        $(".loading-overlay").hide();
      },
      logout: function logout() {
        this.set("session.data.username", "");
        this.set("session.data.password", "");
        this.get("session").invalidate();
      }
    }
  });

  _exports.default = _default;
});