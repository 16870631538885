define("flytrendy/routes/campaigns-pending", ["exports", "ember-simple-auth/mixins/application-route-mixin", "flytrendy/config/environment", "flytrendy/models/objects/campaign"], function (_exports, _applicationRouteMixin, _environment, _campaign) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    session: Ember.inject.service('session'),
    setupController: function setupController(controller, model) {
      // var userauth = this.get("session.data.authenticated.token_type") + " " + this.get("session.data.authenticated.access_token");
      // var brand_id = controller.get("brand");
      // var endpoint = ENV.APP.API_HOST + "/staff/campaigns?pending=true";
      // if(brand_id){
      // 	endpoint = ENV.APP.API_HOST + "/staff/campaigns?pending=true&brand_id="+brand_id;
      // }
      //    Ember.$.ajax({
      // 	type: "GET",
      // 	url: endpoint,
      // 	dataType: "json",
      // 	beforeSend: function(xhr) {
      // 		xhr.setRequestHeader("Authorization", userauth);
      // 		xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
      // 	},
      // 	success: function(res) {
      // 		var data = [];
      // 		if(res && res.getCampaignsStaff){
      // 			var obj = null, campaignList = [], campObj = null;
      // 			for(var i=0;i<res.getCampaignsStaff.length;i++){
      // 				obj = res.getCampaignsStaff[i];
      // 				campObj = Campaign.create(obj);
      // 				campObj.set('data_id', obj.id);
      // 				campObj.setHeroImage(controller);
      // 				data.push(campObj);
      // 			}
      // 		}
      // 		controller.set("data", data);
      // 	},
      // 	error: function(jqXHR, exception) {
      // 		controller.set("data", []);
      // 		console.log(jqXHR);
      // 	}
      // });
      controller.set("data", []);
      controller.send("loadData");
    }
  });

  _exports.default = _default;
});